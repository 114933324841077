import { ItemsService } from './../../shared/services/items.service';
import { LiveOfferFilter, offerBusinessPartner } from './../../shared/interfaces/ILiveOffer';
import { ConfirmationPopupSettings } from './../../shared/interfaces/IConfirmationPopupSettings';
import { computed, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';

import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import * as models from './../../shared/interfaces/model';
import { IItem } from '../interfaces/IItem';
import {
  LiveOffer,
  LiveOfferByIdResponse,
  LiveOfferPayload,
  LiveOfferResponse,
  LiveOfferStateEnum,
  OfferOwnershipPayload,
} from '../../shared/interfaces/ILiveOffer';

@Injectable({
  providedIn: 'root'
})
export class BuyerOffersService {
  buyerOffersPageSize = 25;
  public liveOfferListingSignal: WritableSignal<LiveOfferResponse> = signal({
    pagination: {
      currentPage: 1,
      pageSize: this.buyerOffersPageSize,
      totalCount: 1,
      totalPages: 1,
    },
    values: [],
    counters: {
      all: 0,
      live: 0,
      counteroffers: 0,
      accepted: 0,
      canceled: 0,
      expired: 0
    }
  });

  public showRestrictedCountryPopupSignal: WritableSignal<boolean> = signal(false);
  public isListLoadingSignal: WritableSignal<boolean> = signal(null);
  public isSubscriptionLoadingSignal: WritableSignal<boolean> = signal(false);
  public isOwnershipLoadingSignal: WritableSignal<boolean> = signal(false);
  public isGlobalLoadingSignal: Signal<boolean> = computed(() => this.isListLoadingSignal() || this.isSubscriptionLoadingSignal() || this.isOwnershipLoadingSignal());
  public selectedOfferSignal: WritableSignal<LiveOffer> = signal(undefined);
  confirmAcceptanceSettings: ConfirmationPopupSettings = {
    show: false,
    bodyText: 'Please note that by accepting this live offer, you are committing to a binding order that cannot be canceled or returned.',
    headerText: `Are you ready<br>to accept this offer?`,
    zoomIn: true,
    zoomOut: true,
    acceptButton: {
      show: true,
      text: 'Accept Offer'
    },
    cancelButton: {
      show: true,
      text: 'Back'
    }
  };
  submittedForAcceptanceSettings: ConfirmationPopupSettings = {
    show: false,
    bodyText: `The order will be added<br>to your Order History list.`,
    headerText: `Offer has been accepted`,
    zoomIn: true,
    zoomOut: true,
    acceptButton: {
      show: true,
      text: 'Got it!'
    }
  };
  liveOfferNotificationEnabledSettings: ConfirmationPopupSettings = {
    show: false,
    bodyText: `We'll notify you via SMS or email<br>about any response to the offer.`,
    headerText: `Notification enabled`,
    zoomIn: true,
    zoomOut: true,
    acceptButton: {
      show: true,
      text: 'Got it!'
    }
  };
  liveOffersCounterSignal: WritableSignal<number> = signal(0);
  selectedStateSignal = signal<LiveOfferStateEnum>(LiveOfferStateEnum.All);

  isMobileFiltersOpen = false;
  public liveOffersFilter: LiveOfferFilter = {
    sortingCriteria: 0,
  }

  constructor(
    private http: HttpClient,
    private itemsService: ItemsService
  ) {
  }

  offerBusinessPartners(offerId: string): Observable<offerBusinessPartner> {
    const url = `${environment.adminUrl}Offers/${offerId}/businessPartners`;
    return this.http.get<offerBusinessPartner>(url);
  }

  subscribeToBuyersOffers(offerId: string): Observable<models.OfferSubscriptionResponse> {
    const url = `${environment.buyerPortalBaseUrl}offers/${offerId}/subscription`;
    return this.http.post<models.OfferSubscriptionResponse>(url, null);
  }

  unsubscribeToBuyersOffers(offerId: string): Observable<void> {
    const url = `${environment.buyerPortalBaseUrl}offers/${offerId}/subscription`;
    return this.http.delete<void>(url);
  }

  getLiveOffers(payload: LiveOfferPayload): Observable<LiveOfferResponse> {
    const url = `${environment.buyerPortalBaseUrl}offers/live`;
    return this.http.post<LiveOfferResponse>(url, payload).pipe(
      map((response: LiveOfferResponse) => {
        response.values.map(item => {
          this.itemsService.mapNotificationInfo(item.item);
        });
        return response;
      })
    );
  }

  getLiveOfferById(offerId: string): Observable<LiveOfferByIdResponse> {
    const url = `${environment.buyerPortalBaseUrl}offers/live/${offerId}`;
    return this.http.get<LiveOfferByIdResponse>(url).pipe(
      map((response: LiveOfferByIdResponse) => {
        response.item = this.itemsService.mapNotificationInfo(response.item);
        return response;
      })
    );
  }

  searchLiveOffers(payload: LiveOfferPayload): Observable<LiveOfferResponse> {
    const url = `${environment.buyerPortalBaseUrl}offers/live/search`;
    return this.http.post<LiveOfferResponse>(url, payload);
  }

  getLiveOfferByIdSignal(offerId: string): Signal<LiveOffer> {
    const url = `${environment.buyerPortalBaseUrl}offers/live/${offerId}`;
    return toSignal(this.http.get<LiveOffer>(url).pipe(
      catchError((error) => {
        console.error('Error fetching live offer', error);
        return throwError(() => error);
      }),
    ), { initialValue: null });
  }

  getLiveOffersCounter(): void {
    const url = `${environment.buyerPortalBaseUrl}offers/live/count`;
    this.http.get<number>(url).pipe(
      catchError((error) => {
        console.error('Error fetching live offers count', error);
        return throwError(() => error);
      }),
    ).subscribe((count) => {
      this.liveOffersCounterSignal.set(count);
    });
  }

  updateOfferOwnership(offerOwnershipPayload: OfferOwnershipPayload) {
    const url = `${environment.buyerPortalBaseUrl}offers/${offerOwnershipPayload.id}/ownership`;
    return this.http.put<any>(url, offerOwnershipPayload).pipe(
      catchError((error) => {
        console.error('Error updating offer ownership', error);
        return throwError(() => error);
      }),
    );
  }
}
