import { SharedSource } from './../../../../core/shared-source';
import { SharedValues } from './../../../../core/shared-values';
import { Component, Output, EventEmitter, OnInit, WritableSignal, signal } from '@angular/core';
import { OffersBreadcrumbsMobileComponent } from "../../offers-breadcrumbs-mobile/offers-breadcrumbs-mobile.component";
import { OffersFilterComponent } from '../offers-filter.component';
import { LiveOfferPayload, LiveOfferStateEnum } from '../.../../../../../shared/interfaces/ILiveOffer';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

enum SortOptions {
  RecentlyAdded = 0,
  Oldest = 1,
  HighestTotal = 2,
  LowestTotal = 3,
  MaxPercentOff = 4,
  MinPercentOff = 5,
  SortBySKU = 6
}

@Component({
  selector: 'app-offers-filter-mobile',
  standalone: true,
  imports: [OffersBreadcrumbsMobileComponent, FormsModule, CommonModule
  ],
  templateUrl: './offers-filter-mobile.component.html',
  styleUrl: './offers-filter-mobile.component.scss'
})
export class OffersFilterMobileComponent extends OffersFilterComponent implements OnInit {
  @Output() onCloseClick: EventEmitter<any> = new EventEmitter<any>();

  localSelectedState: LiveOfferStateEnum;
  options = [
    { label: 'Recently Added', value: SortOptions.RecentlyAdded },
    { label: 'Oldest', value: SortOptions.Oldest },
    { label: 'Highest Total', value: SortOptions.HighestTotal },
    { label: 'Lowest Total', value: SortOptions.LowestTotal },
    { label: 'Max Percent Off', value: SortOptions.MaxPercentOff },
    { label: 'Min Percent Off', value: SortOptions.MinPercentOff },
    // { label: 'Sort By SKU', value: SortOptions.SortBySKU }
  ];
  sortBySKU: boolean = false;
  selectedValue: SortOptions | null = null; // SortOptions.RecentlyAdded; 

  ngOnInit(): void {
    this.offerCountersSignal.set(this.buyerOffersService.liveOfferListingSignal().counters);
    this.localSelectedState = this.selectedStateSignal();
    const sortingCriteria = this.buyerOffersService.liveOffersFilter?.sortingCriteria;

    if (sortingCriteria === SortOptions.SortBySKU) {
      this.sortBySKU = true;
      this.selectedValue = null;
    } else {
      this.sortBySKU = false;
      this.selectedValue = sortingCriteria;
    }

  }

  close(): void {
    this.onCloseClick.emit();
  }

  selectStateLocally(state: LiveOfferStateEnum, e?: Event): void {
    if (e) { e.preventDefault(); }
    this.localSelectedState = state;
    this.submitSearch();
  }

  onSelectChange() {
    if (this.selectedValue !== null) {
      this.sortBySKU = false;
    }
    this.submitSearch();
  }

  onCheckboxChange() {
    if (this.sortBySKU) {
      this.selectedValue = null;
    }
    this.submitSearch();
  }

  submitSearch(): void {
    const payload: LiveOfferPayload = {
      pagination: {
        pageSize: this.buyerOffersService.liveOfferListingSignal().pagination.pageSize,
        pageNumber: 1
      },
      filter: this.localSelectedState,
      sortingCriteria: this.sortBySKU ? SortOptions.SortBySKU : (this.selectedValue ? this.selectedValue : SortOptions.RecentlyAdded)
    };

    const sharedValue: SharedValues = {
      name: SharedSource.searchInLiveOffers,
      data: payload
    };
    this.pubSubService.emit(sharedValue);
    this.pubSubService.sharedSubject.next(sharedValue);
  }

  reset() {
    this.localSelectedState = LiveOfferStateEnum.Live;
    this.selectedValue = 0;
    this.sortBySKU = false;
    this.submitSearch();
  }


}
